<template>
  <div>
    <!-- 顶部 -->
    <div class="agreement-header-wrap">
      <!-- PC -->
      <div class="pc-header">
          <div class="main">
         <a href="" target="_blank" class="logo"
            >科汇云</a
          >
        </div>
      </div>
      <!-- H5 -->
      <!-- <div class="m-header">
        <i class="goback" onclick="javascript:history.back()"></i>
        <div class="title">科汇云会员服务协议</div>
      </div>-->
      <div class="agreement-blank"></div> 
    </div>
    <!-- 内容 -->
    <div class="agreement-con">
      <h2><strong>科汇云会员服务协议</strong></h2>
      <p>
        欢迎阅读科汇云信息技术（重庆）有限公司(下称“本协议”)，您应当在使用服务之前认真阅读本协议全部内容。本协议阐述之条款和条件适用于您使用科汇云网站（所涉域名为：www.kehui.cloud，下同）所提供的各种工具和服务(下称“服务”)。以下所述条款和条件即构成您与科汇云就使用服务所达成的协议。一旦您使用了该服务，即表示您已接受了以下所述的条款和条件，即表示您已同意接受本协议约束，包括但不限于本协议。<strong
          >如果您不同意接受全部的条款和条件，那么您将无权使用该服务。</strong
        >
      </p>
      <h3><strong>1、接受条款</strong></h3>
      <p>
        以任何方式进入并使用科汇云网站服务即表示您已充分阅读、理解并同意自己已经与科汇云订立本协议，且您将受本协议的条款和条件(“条款”)
        约束。科汇云可随时自行全权决定更改“条款”。如“条款”有任何变更，科汇云仅将在网站上发布新条款予以公示，不再单独通知予您。如您不同意相关变更，则必须停止使用“服务”。经修订的“条款”一经在科汇云网站公布后，立即自动生效。一旦您继续使用“服务”，则表示您已接受经修订的“条款”，当您与科汇云发生争议时，应以最新的“条款”为准。除另行明确声明外，任何使“服务”范围扩大或功能增强的新内容均受本协议约束。
      </p>
      <p>
        本协议内容包括协议正文及所有科汇云已经发布或将来可能发布的各类规则。所有规则为本协议不可分割的一部分，与本协议正文具有同等法律效力。
      </p>
      <h3><strong>2、服务使用对象</strong></h3>
      <p>
        “服务”仅供能够根据相关法律订立具有法律约束力的合约的自然人、法人或其他组织使用。因此，若是自然人您的年龄必须在十八周岁或以上，若是法人或其他组织必须是在工商局或相关机构合法登记注册，才可使用科汇云服务。如不符合本项条件，请勿使用“服务”。科汇云可随时自行全权决定拒绝向任何服务对象提供“服务”。“服务”不会提供给被暂时或永久中止资格的科汇云会员。
      </p>
      <h3><strong>3、收费</strong></h3>
      <p>
        科汇云保留在根据第1条通知您后，收取“服务”费用的权利。您因进行交易、向科汇云获取有偿服务或接触科汇云服务器而发生的所有应纳税赋，以及相关硬件、软件、通讯、网络服务及其他方面的费用均由您自行承担。科汇云保留在无须发出书面通知，仅在科汇云网站公示的情况下，暂时或永久地更改或停止部分或全部“服务”的权利。
      </p>
      <h3><strong>4、科汇云网站仅作为信息发布或交易地点</strong></h3>
      <p>
        您完全了解科汇云网站上的信息系用户自行发布，且可能存在风险和瑕疵。科汇云网站仅作为用户物色交易对象，就能力应用和技术交易进行协商，以及获取各类与能力应用和技术交易相关的服务的地点。但是，科汇云不能控制和负责交易所涉及的物品的质量、安全或合法性，科汇云不能保证已发布的信息的真实性或准确性，以及交易方履行其在贸易协议项下的各项义务的能力。科汇云不能也不会控制交易各方能否履行协议义务。此外，您应注意到，与外国国民、未成年人或以欺诈手段行事的人/单位进行交易的风险是客观存在的。您应自行谨慎判断确定相关物品及/或信息的真实性、合法性和有效性，并自行承担因此产生的责任与损失。
      </p>
      <h3><strong>5、您的资料</strong></h3>
      <p>
        “您的资料”包括您在注册、交易、交流或列举物品过程中、在任何公开信息场合或通过任何电子邮件形式，向科汇云或其他用户提供的任何资料，包括数据、文本、软件、音乐、声响、照片、图画、影像、词句或其他材料。您应对“您的资料”负全部责任，而科汇云仅作为您在网上发布和刊登“您的资料”的被动渠道。但是，倘若科汇云认为“您的资料”可能使科汇云承担任何法律或道义上的责任，或可能使科汇云
        (全部或部分地)
        失去科汇云的互联网服务供应商或其他供应商的服务，或您未在科汇云规定的期限内登录或再次登录网站，则科汇云可自行全权决定对“您的资料”采取科汇云认为必要或适当的任何行动，包括但不限于删除该类资料。您特此保证，您对提交给科汇云的“您的资料”拥有全部权利，包括全部版权。您确认，科汇云没有责任去认定或决定您提交给科汇云的资料哪些是应当受到保护的，对享有“服务”的其他用户使用“您的资料”，科汇云也不必负责。
      </p>
      <p><strong>5.1 注册义务</strong></p>
      <p>
        如您在科汇云网站注册，您同意：(a)
        根据科汇云网站刊载的会员资料表格的要求，提供关于您或贵单位的真实、准确、完整和反映当前情况的资料；(b)
        维持并及时更新会员资料，使其保持真实、准确、完整和反映当前情况。倘若您提供任何不真实、不准确、不完整或不能反映当前情况的资料，或科汇云有合理理由怀疑该等资料不真实、不准确、不完整或不能反映当前情况，科汇云有权暂停或终止您的注册身份及资料，并拒绝您在目前或将来对“服务”(或其任何部份)
        以任何形式使用。如您代表一家公司或其他法律主体在科汇云登记，则您声明和保证，您有权使该公司或其他法律主体受本协议“条款”约束。
      </p>
      <p><strong>5.2 会员注册名（账号）、密码和保密</strong></p>
      <p>
        在您按照注册页面提示填写信息、阅读并同意本协议并完成全部注册程序后或以其他科汇云允许的方式实际使用科汇云网站服务时，您即成为科汇云会员（亦称会员），科汇云根据会员注册名（账号）和密码确认您的身份。您须自行负责对您的会员注册名（账号）和密码保密，且须对您在会员注册名（账号）和密码下发生的所有活动（包括但不限于发布信息资料、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。您同意：(a)
        如发现任何人未经授权使用您的会员注册名（账号）或密码，或发生违反保密规定的任何其他情况，您会立即书面通知科汇云；及
        (b)
        确保您在每个上网时段结束时，以正确步骤离开网站。科汇云不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。您理解科汇云对您的请求采取行动需要合理时间，科汇云对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。
      </p>
      <p><strong>5.3 交易认证义务</strong></p>
      <p>
        如您在科汇云网站交易时，您应按交易页面提示填写《企业/机构交易认证申请表》供申请材料，阅读并同意交易页面的所有协议（包括但不限于“安心签”的服务协议、隐私条款、数字证书管理等）并通过审核后，才能进行交易。科汇云根据您绑定的银行卡和预留的手机号确认您的交易账户。您须自行负责对您的交易信息及密码保密，且须对您在科汇云网站上进行的所有交易承担责任。您同意：(a)
        如发现任何人未经授权使用您的账户进行交易，或发生违反保密规定的任何其他情况，您会立即书面通知科汇云；及
        (b)
        确保您在每个上网时段结束时，以正确步骤离开网站。科汇云不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。您理解科汇云对您的请求采取行动需要合理时间，科汇云对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。
      </p>
      <p><strong>5.4 关于您的资料的规则</strong></p>
      <p>
        您同意，“您的资料”和您发布在科汇云网站上的任何“物品”（泛指一切可供依法交易的、有形的或无形的、以各种形态存在的某种具体的物品，或某种权利或利益，或某种票据或证券，或某种服务或行为。本协议中“物品”一词均含此义）
      </p>
      <p>a. 不会有欺诈成份，与售卖伪造或盗窃无涉；</p>
      <p>
        b.
        不会侵犯任何第三者对该物品享有的物权，或版权、专利、商标、商业秘密或其他
        知识产权，或隐私权、名誉权等任何权利；
      </p>
      <p>
        c. 不会违反任何法律、法规、条例或规章
        (包括但不限于关于规范出口管理、贸易配额、保护消费者、不正当竞争或虚假广告的法律、法规、条例或规章)；
      </p>
      <p>d. 不会含有诽谤（包括商业诽谤）、恐吓或骚扰的内容；</p>
      <p>e. 不会含有淫秽、或包含任何儿童色情内容；</p>
      <p>
        f.
        不会含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序；
      </p>
      <p>
        g.
        不会直接或间接与下述各项货物或服务连接，或包含对下述各项货物或服务的描述：(i)
        本协议项下禁止的货物或服务；或 (ii) 您无权连接或包含的货物或服务。
      </p>
      <p>此外，您同意不会：</p>
      <p>
        (h)
        在与任何连锁信件、大量胡乱邮寄的电子邮件、滥发电子邮件或任何复制或多余的信息有关的方面使用“服务”；
      </p>
      <p>
        (i) 未经其他人士同意，利用“服务”收集其他人士的电子邮件地址及其他资料；
      </p>
      <p>
        或 (j)
        利用“服务”制作虚假的电子邮件地址，或以其他形式试图在发送人的身份或信息的来源方面误导其他人士。
      </p>
      <p><strong>5.5 被禁止物品</strong></p>
      <p>
        您不得在科汇云网站公布或通过科汇云网站买卖：(a)
        可能使科汇云违反任何相关法律、法规、条例或规章的任何物品；或 (b)
        科汇云认为应禁止或不适合通过本网站买卖的任何物品。
      </p>
      <h3>
        <strong
          >6、您完全理解并同意不可撤销地授予科汇云及其关联公司下列权利：</strong
        >
      </h3>
      <p>
        <strong
          >6.1
          对于您提供的资料，您授予科汇云及其关联公司独家的、全球通用的、永久的、免费的许可使用权利
          (并有权在多个层面对该权利进行再授权)，使科汇云及其关联公司有权(全部或部份地)
          使用、复制、修订、改写、发布、翻译、分发、执行和展示"您的资料"或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将"您的资料"纳入其他作品内。</strong
        >
      </p>
      <p>
        <strong
          >6.2
          当您违反本协议或与科汇云签订的其他协议的约定，科汇云有权以任何方式通知关联公司（包括但不限于代理商等，下同），要求其对您的权益采取限制措施包括但不限于要求第三方支付机构将您帐户内的款项支付给科汇云指定的对象，要求关联公司中止、终止对您提供部分或全部服务，且在其经营或实际控制的任何网站公示您的违约情况。</strong
        >
      </p>
      <p>
        <strong
          >6.3
          同样，当您向科汇云关联公司作出任何形式的承诺，且相关公司已确认您违反了该承诺，则科汇云有权立即按您的承诺约定的方式对您的账户采取限制措施，包括但不限于中止或终止向您提供服务，并公示相关公司确认的您的违约情况。您了解并同意，科汇云无须就相关确认与您核对事实，或另行征得您的同意，且科汇云无须就此限制措施或公示行为向您承担任何的责任。</strong
        >
      </p>
      <h3><strong>7、隐私</strong></h3>
      <p>
        尽管有第6条所规定的许可使用权，但基于保护您的隐私是科汇云的一项基本原则，为此科汇云还将根据以下隐私政策条款使用"您的资料"。，因此，您必须仔细阅读该部分内容。请注意，您一旦自愿地在科汇云交易地点披露"您的资料"，该等资料即可能被其他人士获取和使用。
      </p>
      <p><strong>7.1 个人或组织信息</strong></p>
      <p>
        当您在注册为会员时，我们要求您验证手机号码，并设定密码和会员类型。另外，为了让您更好的使用科汇云的服务，您还被要求在使用各类服务前（如发布技术成果）提供您的真实姓名、性别、所在地、所属行业、公司名称、公司的地址等等相关信息。在使用交易等相关服务时，我们要求您提供身份等信息以完成实名认证。您还有权选择随时来填写附加信息。这些信息可能包括您所在的省份和城市，教育信息，职业信息，经营信息以及为获得更深层次服务而提供的个人或组织信息。我们通过用户填写的注册信息及附加信息来获得会员的统计资料。我们将会用这些统计数据来给我们的会员分类，例如个人、企业等，以便有针对性地向我们的会员提供新的服务和机会。我们会通过您的邮件地址来通知您这些新的服务和机会。
      </p>
      <p><strong>7.2 信息自动收集与Cookie的使用</strong></p>
      <p>
        科汇云有可能自动接收并记录您的浏览器和计算机上的信息，其中包括您的 IP
        地址，科汇云cookie 中的信息、软硬件特征信息、以及您需求的网页记录。
      </p>
      <p>
        cookies是少量的数据，在您未拒绝接受cookies的情况下，cookies将被发送到您的浏览器，并储存在您的计算机硬盘。我们使用cookies储存您访问我们网站的相关数据，在您访问或再次访问我们的网站时,我们能识别您的身份，并通过分析数据为您提供更好更多的服务。
      </p>
      <p>
        您有权选择接受或拒绝接受cookies。您可以通过修改浏览器的设置以拒绝接受cookies，但是我们需要提醒您，因为您拒绝接受cookies，您可能无法使用依赖于cookies的我们网站的部分功能。
      </p>
      <p><strong>7.3 您的交易行为</strong></p>
      <p>
        我们跟踪IP地址是出于身份和交易真实性的识别、安全考虑和其它符合国家规定的要求。如果我们没有发现任何安全问题，我们会在六十天后删除我们收集到的IP地址。我们还跟踪全天的页面访问数据。全天页面访问数据被用来反映网站的流量，以使我们可以为未来的发展制定计划（例如，增加服务器）。
      </p>
      <p>
        因您交易行为而产生的其它数据，我们将会用这些统计数据来给会员分类，以便有针对性地向我们的会员提供新的服务和机会。我们会通过您的邮件地址来通知您这些新的服务和机会。
      </p>
      <p>我们的一些服务需要付费，我们会合理获取您的信用卡信息。</p>
      <p><strong>7.4 信息的披露和使用</strong></p>
      <p>
        我们不会向任何无关第三方提供，出售，出租，分享和交易用户的个人信息，但为方便您使用科汇云服务及科汇云关联公司或其他组织的服务（以下称其他服务），您同意并授权科汇云将您的个人信息传递给您同时接受其他服务的科汇云关联公司或其他组织，或从为您提供其他服务的科汇云关联公司或其他组织获取您的个人信息。
      </p>
      <p>
        我们会对您及（或）您的公司的身份和交易数据进行综合统计，并以科汇云判断合理的目的和用途将该综合统计向广告主披露。但是，在这些情况下，我们不会向这些机构披露任何可能用以识别用户的个人身份的资料，但从用户的用户名或其它可披露资料分析得出的资料不受此限。
      </p>
      <p>
        您同意我们可批露或使用您的个人信息以用于识别和（或）确认您的身份，或解决争议，或有助于确保网站安全、限制欺诈、非法或其他刑事犯罪活动，以执行我们的服务协议。
      </p>
      <p>
        您同意我们可批露或使用您的个人信息以保护您的生命、财产之安全或为防止严重侵害他人之合法权益或为公共利益之需要。
      </p>
      <p>
        您同意我们可批露或使用您的个人信息以改进我们的服务，并使我们的服务更能符合您的要求，从而使您在使用我们服务时得到更好的使用体验。
      </p>
      <p>
        您同意我们利用您的个人信息与您联络，并向您提供您感兴趣的信息，如：科技成果信息。您接受“服务协议”即为明示同意收取这些资料。
      </p>
      <p>
        我们的网站公布了用户提交的商业机会和报价，而其他用户可以查询这些报价和商业机会。
      </p>
      <p>
        当我们被法律强制或依照政府或依权利人因识别涉嫌侵权行为人的要求而提供您的信息时，我们将善意地披露您的资料。
      </p>
      <p><strong>7.5 信息的存储和交换</strong></p>
      <p>所收集的用户信息和资料将保存在科汇云及（或）其关联公司的服务器上。</p>
      <p><strong>7.6 外部链接</strong></p>
      <p>
        本站含有到其他网站的链接。科汇云对那些网站的隐私保护措施不负任何责任。我们可能在任何需要的时候增加商业伙伴或共用品牌的网站。
      </p>
      <p><strong>7.7 公开的交易信息</strong></p>
      <p>
        您提供发布的供贸易的物品信息或报价将被显示在公共区域，对任何用户来说是公开的。请注意所有在这些场所公开的信息会成为公共信息，请您在决定公布您的个人信息前仔细考虑。
      </p>
      <p><strong>7.8 安全</strong></p>
      <p>
        我们网站有相应的安全措施来确保我们掌握的信息不丢失，不被滥用和变造。这些安全措施包括向其它服务器备份数据和对用户密码加密。尽管我们有这些安全措施，但请注意在因特网上不存在“完善的安全措施”。
      </p>
      <h3><strong>8、信息发布及交易程序</strong></h3>
      <p><strong>8.1 添加信息描述条目</strong></p>
      <p>
        信息描述是由您提供的在科汇云网站上展示的文字描述、图画和/或照片，可以是(a)
        对您拥有而您希望供应的技术成果或服务的描述；或 (b)
        对您正寻找的技术成果、创业项目、投资意向或服务的描述。您可在科汇云网站发布任一类信息描述，或两种类型同时发布，条件是，您必须将该等信息描述归入正确的类目内。科汇云不对信息描述的准确性或内容负责。
      </p>
      <p><strong>8.2 就交易进行协商</strong></p>
      <p>
        交易各方通过在科汇云网站上明确描述报盘和回盘，进行相互协商。所有各方接纳报盘或回盘将使所涉及的科汇云用户有义务完成交易。除非在特殊情况下，诸如用户在您提出报盘后实质性地更改对物品的描述或澄清任何文字输入错误，或您未能证实交易所涉及的用户的身份等，报盘和承诺均不得撤回。
      </p>
      <p><strong>8.3 处理交易争议</strong></p>
      <p>
        (i)科汇云不涉及用户间因交易而产生的法律关系及法律纠纷，不会且不能牵涉进交易各方的交易当中。倘若您与一名或一名以上用户，或与您通过科汇云网站获取其服务的第三者服务供应商发生争议，您免除科汇云
        (及科汇云代理人和雇员)
        在因该等争议而引起的，或在任何方面与该等争议有关的不同种类和性质的任何(实际和后果性的)
        权利主张、要求和损害赔偿等方面的责任。
      </p>
      <p>
        (ii)科汇云有权受理并调处您与其他用户因交易产生的争议，同时有权单方面独立判断其他用户对您的投诉及(或)索偿是否成立，若科汇云判断索偿成立，则您应及时使用自有资金进行偿付，否则科汇云有权使用您交纳的保证金（如有）或扣减已购科汇云及其关联公司的产品或服务中未履行部分的费用的相应金额或您在科汇云网站所有账户下的其他资金(或权益)等进行相应偿付。科汇云没有使用自用资金进行偿付的义务，但若进行了该等支付，您应及时赔偿科汇云的全部损失，否则科汇云有权通过前述方式抵减相应资金或权益，如仍无法弥补科汇云损失，则科汇云保留继续追偿的权利。因科汇云非司法机构，您完全理解并承认，科汇云对证据的鉴别能力及对纠纷的处理能力有限，受理贸易争议完全是基于您之委托，不保证争议处理结果符合您的期望，亦不对争议处理结果承担任何责任。科汇云有权决定是否参与争议的调处。
      </p>
      <p>
        (iii)
        科汇云有权通过电子邮件等联系方式向您了解情况，并将所了解的情况通过电子邮件等方式通知对方，您有义务配合科汇云的工作，否则科汇云有权做出对您不利的处理结果。
      </p>
      <p>
        (ⅳ)经生效法律文书确认用户存在违法或违反本协议行为或者科汇云自行判断用户涉嫌存在违法或违反本协议行为的，科汇云有权在科汇云中文网站上以网络发布形式公布用户的违法行为并做出处罚（包括但不限于限权、终止服务等）。
      </p>
      <p><strong>8.4 运用常识</strong></p>
      <p>
        科汇云不能亦不试图对其他用户通过“服务”提供的资料进行控制。就其本质而言，其他用户的资料，可能是令人反感的、有害的或不准确的，且在某些情况下可能带有错误的标识说明或以欺诈方式加上标识说明。科汇云希望您在使用科汇云网站时，小心谨慎并运用常识。
      </p>
      <h3><strong>9、交易系统</strong></h3>
      <p><strong>9.1 不得操纵交易</strong></p>
      <p>
        您同意不利用帮助实现蒙蔽或欺骗意图的同伙(下属的客户或第三方)，操纵与另一交易方所进行的商业谈判的结果。
      </p>
      <p><strong>9.2 系统完整性</strong></p>
      <p>
        您同意，您不得使用任何装置、软件或例行程序干预或试图干预科汇云网站的正常运作或正在科汇云网站上进行的任何交易。您不得采取对任何将不合理或不合比例的庞大负载加诸科汇云网络结构的行动。您不得向任何第三者披露您的密码，或与任何第三者共用您的密码，或为任何未经批准的目的使用您的密码。
      </p>
      <p><strong>9.3 反馈</strong></p>
      <p>
        您不得采取任何可能损害信息反馈系统的完整性的行动，诸如：利用第二会员身份标识或第三者为您本身留下正面反馈；利用第二会员身份标识或第三者为其他用户留下负面反馈
        (反馈数据轰炸)；或在用户未能履行交易范围以外的某些行动时，留下负面的反馈
        (反馈恶意强加)。
      </p>
      <p><strong>9.4 不作商业性利用</strong></p>
      <p>
        您同意，您不得对任何资料作商业性利用，包括但不限于在未经科汇云授权高层管理人员事先书面批准的情况下，复制在科汇云网站上展示的任何资料并用于商业用途。
      </p>
      <h3><strong>10、终止或访问限制</strong></h3>
      <p>
        您同意，在科汇云未向您收费的情况下，科汇云可自行全权决定以任何理由
        (包括但不限于科汇云认为您已违反本协议的字面意义和精神，或您以不符合本协议的字面意义和精神的方式行事，或您在超过90天的时间内未以您的账号及密码登录网站)
        终止您的“服务”密码、帐户 (或其任何部份)
        或您对“服务”的使用，并删除和丢弃您在使用“服务”中提交的
        “您的资料”。您同意，在科汇云向您收费的情况下，科汇云应基于合理的怀疑且经电子邮件通知的情况下实施上述终止服务的行为。科汇云同时可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供“服务”或其任何部份。您同意，根据本协议的任何规定终止您使用“服务”之措施可在不发出事先通知的情况下实施，并承认和同意，科汇云可立即使您的帐户无效，或撤销您的帐户以及在您的帐户内的所有相关资料和档案，和/或禁止您进一步接入该等档案或“服务”。账号终止后，科汇云没有义务为您保留原账号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给您或第三方。此外，您同意，科汇云不会就终止您接入“服务”而对您或任何第三者承担任何责任。第12、13、14和22各条应在本协议终止后继续有效。
      </p>
      <h3><strong>11、违反规则会有什么后果？</strong></h3>
      <p>
        在不限制其他补救措施的前提下，发生下述任一情况，科汇云可立即发出警告，暂时中止、永久中止或终止您的会员资格，删除您的任何现有技术成果、创业项目、投资意向或服务产品信息，以及您在网站上展示的任何其他资料：(i)
        您违反本协议；(ii) 科汇云无法核实或鉴定您向科汇云提供的任何资料；或
        (iii)
        科汇云相信您的行为可能会使您、科汇云用户或通过科汇云或科汇云网站提供服务的第三者服务供应商发生任何法律责任。在不限制任何其他补救措施的前提下，倘若发现您从事涉及科汇云网站的诈骗活动，科汇云可暂停或终止您的帐户。
      </p>
      <h3><strong>12、服务“按现状”提供</strong></h3>
      <p>
        科汇云会尽一切努力使您在使用科汇云网站的过程中得到乐趣。遗憾的是，科汇云不能随时预见到任何技术上的问题或其他困难。该等困难可能会导致数据损失或其他服务中断。为此，您明确理解和同意，您使用“服务”的风险由您自行承担。“服务”以“按现状”和“按可得到”的基础提供。科汇云明确声明不作出任何种类的所有明示或暗示的保证，包括但不限于关于适销性、适用于某一特定用途和无侵权行为等方面的保证。科汇云对下述内容不作保证：(i)“服务”会符合您的要求；(ii)“服务”不会中断，且适时、安全和不带任何错误；(iii)
        通过使用“服务”而可能获取的结果将是准确或可信赖的；及 (iv)
        您通过“服务”而购买或获取的任何产品、服务、资料或其他材料的质量将符合您的预期。通过使用“服务”而下载或以其他形式获取任何材料是由您自行全权决定进行的，且与此有关的风险由您自行承担，对于因您下载任何该等材料而发生的您的电脑系统的任何损毁或任何数据损失，您将自行承担责任。您从科汇云或通过或从“服务”获取的任何口头或书面意见或资料，均不产生未在本协议内明确载明的任何保证。
      </p>
      <h3><strong>13、责任范围</strong></h3>
      <p>
        您明确理解和同意，科汇云不对因下述任一情况而发生的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其他无形损失的损害赔偿
        (无论科汇云是否已被告知该等损害赔偿的可能性)：(i)
        使用或未能使用“服务”；(ii)
        因通过或从“服务”购买或获取任何货物、样品、数据、资料或服务，或通过或从“服务”接收任何信息或缔结任何交易所产生的获取替代货物和服务的费用；(iii)
        未经批准接入或更改您的传输资料或数据；(iv)
        任何第三者对“服务”的声明或关于“服务”的行为；或 (v)
        因任何原因而引起的与“服务”有关的任何其他事宜，包括疏忽。
      </p>
      <h3><strong>14、赔偿</strong></h3>
      <p>
        您同意，因您违反本协议或经在此提及而纳入本协议的其他文件，或因您违反了法律或侵害了第三方的权利，而使第三方对科汇云及其子公司、分公司、董事、职员、代理人提出索赔要求（包括司法费用包括但不限于诉讼费、律师费、鉴定费和其他专业人士的费用），您必须赔偿给科汇云及其子公司、分公司、董事、职员、代理人，使其等免遭损失。
      </p>
      <h3><strong>15、遵守法律</strong></h3>
      <p>
        您应遵守与您使用“服务”，以及与您竞买、购买和销售任何物品以及提供商贸信息有关的所有相关的法律、法规、条例和规章。
      </p>
      <h3><strong>16、无代理关系</strong></h3>
      <p>
        您与科汇云仅为独立订约人关系。本协议无意结成或创设任何代理、合伙、合营、雇用与被雇用或特许权授予与被授予关系。
      </p>
      <h3><strong>17、广告和金融服务</strong></h3>
      <p>
        您与在“服务”上或通过“服务”物色的刊登广告人士通讯或进行业务往来或参与其推广活动，包括就相关货物或服务付款和交付相关货物或服务，以及与该等业务往来相关的任何其他条款、条件、保证或声明，仅限于在您和该刊登广告人士之间发生。您同意，对于因任何该等业务往来或因在“服务”上出现该等刊登广告人士而发生的任何种类的任何损失或损毁，科汇云无需负责或承担任何责任。您如打算通过“服务”创设或参与与任何公司、股票行情、投资或证券有关的任何服务，或通过“服务”收取或要求与任何公司、股票行情、投资或证券有关的任何新闻信息、警戒性信息或其他资料，敬请注意，科汇云不会就通过“服务”传送的任何该等资料的准确性、有用性或可用性、可获利性负责或承担任何责任，且不会对根据该等资料而作出的任何交易或投资决策负责或承担任何责任。
      </p>
      <h3><strong>18、我们可能发送的信息</strong></h3>
      <p>
        您理解并同意，科汇云可通过网页公告、电子邮件、站内信、短信、电话、系统信息以及即时通信等以上一种或多种方式向您发送通知、告知、展示广告及其他商业或非商业性质的信息，具体形式、频次及内容等以实际内容为准。
      </p>
      <p>
        科汇云仅依法履行相应的广告及推广义务，并不对广告内容或推广信息的真实性、合法性负责。因此，您应当自行判断相关广告或推广信息的真实性、合法性和可靠性，如因该广告或推广信息进行的购买、交易或因前述广告或推广信息遭受的损害或损失应由您自行承担，科汇云不承担责任。
      </p>
      <p>
        我们将在必要时（如系统维护暂停某服务、维修完毕恢复某服务等）向您发出与服务有关的通知或公告，届时如给您造成不便或打扰，烦请谅解。
      </p>
      <h3><strong>19、链接</strong></h3>
      <p>
        “服务”或第三者均可提供与其他万维网网站或资源的链接。由于科汇云并不控制该等网站和资源，您承认并同意，科汇云并不对该等外在网站或资源的可用性负责，且不认可该等网站或资源上或可从该等网站或资源获取的任何内容、宣传、产品、服务或其他材料，也不对其等负责或承担任何责任。您进一步承认和同意，对于任何因使用或信赖从此类网站或资源上获取的此类内容、宣传、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失，科汇云均不承担责任。
      </p>
      <h3><strong>20、通知</strong></h3>
      <p>
      除非另有明确规定，任何通知应以电子邮件形式发送，科汇云的电子邮件地址为277729968@qq.com，或（就您而言）发送到您在登记过程中向科汇云提供的电子邮件地址，或有关方指明的该等其他地址。在电子邮件发出二十四（24）小时后，通知应被视为已送达，除非发送人被告知相关电子邮件地址已作废。或者，科汇云可通过邮资预付挂号邮件并要求回执的方式，将通知发到您在登记过程中向科汇云提供的地址。在该情况下，在付邮当日三（3）天后通知被视为已送达。
      </p>
      <h3><strong>21、不可抗力</strong></h3>
      <p>
        对于因科汇云合理控制范围以外的原因，包括但不限于自然灾害、疫情、罢工或骚乱、物质短缺或定量配给、暴动、战争行为、政府行为、通讯或其他设施故障或严重伤亡事故等，致使科汇云延迟或未能履约的，科汇云不对您承担任何责任。
      </p>
      <h3><strong>22、转让</strong></h3>
      <p>科汇云转让本协议无需经您同意。</p>
      <h3><strong>23、其他规定</strong></h3>
      <p>
        本协议取代您和科汇云先前就相同事项订立的任何书面或口头协议。本协议各方面应受中华人民共和国大陆地区法律的管辖。倘若本协议任何规定被裁定为无效或不可强制执行，该项规定应被撤销，而其余规定应予执行。条款标题仅为方便参阅而设，并不以任何方式界定、限制、解释或描述该条款的范围或限度。科汇云未就您或其他人士的某项违约行为采取行动，并不表明科汇云撤回就任何继后或类似的违约事件采取行动的权利。
       </p>
      <h3><strong>24、诉讼</strong></h3>
      <p>
       因本协议或科汇云服务所引起或与其有关的任何争议应向四川省达州市达川区人民法院提起诉讼，并以中华人民共和国法律为管辖法律。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "member",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

 <style scoped src="./agreement.css"></style>
